import React from "react";
import "./FuerteColcura.css";

import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

import imgFuerte1 from "../../assets/img/fuerte-0001.JPG";
import imgFuerte2 from "../../assets/img/fuerte-0002.JPG";
import imgFuerte3 from "../../assets/img/fuerte-0003.JPG";
import imgFuerte4 from "../../assets/img/fuerte-0004.JPG";
import imgFuerte5 from "../../assets/img/fuerte-0005.JPG";
import imgFuerte6 from "../../assets/img/fuerte-0006.JPG";
import imgFuerte7 from "../../assets/img/fuerte-0007.JPG";
import imgFuerte8 from "../../assets/img/fuerte-0008.JPG";
import imgFuerte9 from "../../assets/img/fuerte-0009.JPG";
import imgFuerte10 from "../../assets/img/fuerte-0010.JPG";
import imgFuerte11 from "../../assets/img/fuerte-0011.JPG";
import imgFuerte12 from "../../assets/img/fuerte-0012.JPG";
import imgFuerte13 from "../../assets/img/fuerte-0013.JPG";
import imgFuerte14 from "../../assets/img/fuerte-0014.JPG";
import imgFuerte15 from "../../assets/img/fuerte-0015.JPG";
import imgFuerte16 from "../../assets/img/fuerte-0016.JPG";
import imgFuerte17 from "../../assets/img/fuerte-0017.JPG";
import imgFuerte18 from "../../assets/img/fuerte-0018.JPG";
import imgFuerte19 from "../../assets/img/fuerte-0019.JPG";
import imgFuerte20 from "../../assets/img/fuerte-0020.JPG";
import imgFuerte21 from "../../assets/img/fuerte-0021.JPG";
import imgFuerte22 from "../../assets/img/fuerte-0022.JPG";
import imgFuerte23 from "../../assets/img/fuerte-0023.JPG";
import imgFuerte24 from "../../assets/img/fuerte-0024.JPG";
import imgFuerte25 from "../../assets/img/fuerte-0025.JPG";
import imgFuerte26 from "../../assets/img/fuerte-0026.JPG";
import imgFuerte27 from "../../assets/img/fuerte-0027.JPG";
import imgFuerte28 from "../../assets/img/fuerte-0028.JPG";
import imgFuerte29 from "../../assets/img/fuerte-0029.JPG";
import imgFuerte30 from "../../assets/img/fuerte-0030.JPG";
import imgFuerte31 from "../../assets/img/fuerte-0031.JPG";
import imgFuerte32 from "../../assets/img/fuerte-0032.JPG";
import imgFuerte33 from "../../assets/img/fuerte-0033.JPG";
import imgFuerte34 from "../../assets/img/fuerte-0034.JPG";
import imgFuerte35 from "../../assets/img/fuerte-0035.JPG";
import imgFuerte36 from "../../assets/img/fuerte-0036.JPG";
import imgFuerte37 from "../../assets/img/fuerte-0037.JPG";
import imgFuerte38 from "../../assets/img/fuerte-0038.JPG";
import imgFuerte39 from "../../assets/img/fuerte-0039.JPG";
import imgFuerte40 from "../../assets/img/fuerte-0040.JPG";
import imgFuerte41 from "../../assets/img/fuerte-0041.JPG";
import imgFuerte42 from "../../assets/img/fuerte-0042.JPG";
import imgFuerte43 from "../../assets/img/fuerte-0043.JPG";
import imgFuerte44 from "../../assets/img/fuerte-0044.JPG";
import imgFuerte45 from "../../assets/img/fuerte-0045.JPG";
import imgFuerte46 from "../../assets/img/fuerte-0046.JPG";
import imgFuerte47 from "../../assets/img/fuerte-0047.JPG";
import imgFuerte48 from "../../assets/img/fuerte-0048.JPG";
import imgFuerte49 from "../../assets/img/fuerte-0049.JPG";
import imgFuerte50 from "../../assets/img/fuerte-0050.JPG";
import imgFuerte51 from "../../assets/img/fuerte-0051.JPG";
import imgFuerte52 from "../../assets/img/fuerte-0052.JPG";
import imgFuerte53 from "../../assets/img/fuerte-0053.JPG";
import imgFuerte54 from "../../assets/img/fuerte-0054.JPG";
import imgFuerte55 from "../../assets/img/fuerte-0055.JPG";
import imgFuerte56 from "../../assets/img/fuerte-0056.JPG";
import imgFuerte57 from "../../assets/img/fuerte-0057.JPG";
import imgFuerte58 from "../../assets/img/fuerte-0058.JPG";
import imgFuerte59 from "../../assets/img/fuerte-0059.JPG";
import imgFuerte60 from "../../assets/img/fuerte-0060.JPG";
import imgFuerte61 from "../../assets/img/fuerte-0061.JPG";
import imgFuerte62 from "../../assets/img/fuerte-0062.JPG";
import imgFuerte63 from "../../assets/img/fuerte-0063.JPG";
import imgFuerte64 from "../../assets/img/fuerte-0064.JPG";
import imgFuerte65 from "../../assets/img/fuerte-0065.JPG";
import imgFuerte66 from "../../assets/img/fuerte-0066.JPG";
import imgFuerte67 from "../../assets/img/fuerte-0067.JPG";
import imgFuerte68 from "../../assets/img/fuerte-0068.JPG";
import imgFuerte69 from "../../assets/img/fuerte-0069.JPG";
import imgFuerte70 from "../../assets/img/fuerte-0070.JPG";
import imgFuerte71 from "../../assets/img/fuerte-0071.JPG";
import imgFuerte72 from "../../assets/img/fuerte-0072.JPG";
import imgFuerte73 from "../../assets/img/fuerte-0073.JPG";
import imgFuerte74 from "../../assets/img/fuerte-0074.JPG";
import imgFuerte75 from "../../assets/img/fuerte-0075.JPG";
import imgFuerte76 from "../../assets/img/fuerte-0076.jpg";
import imgFuerte77 from "../../assets/img/fuerte-0077.png";

import mapFuerte from "../../assets/pdf/plano_fuerte_colcura.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("fortPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgFuerte77} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("fortPage.intervention-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("fortPage.intervention-article.title")}
            </Card.Title>
            <Card.Text>
              {t("fortPage.intervention-article.decree-1")}
              <a
                href="https://www.monumentos.gob.cl/servicios/decretos/803_1977"
                target="_blank"
                rel="noreferrer"
              >
                {t("fortPage.intervention-article.decree-2")}
              </a>
            </Card.Text>
            <Card.Text>{t("fortPage.intervention-article.category")}</Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("fortPage.intervention-article.subtitle-1")}
            </Card.Subtitle>
            <Card.Text>{t("fortPage.intervention-article.owner")}</Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("fortPage.intervention-article.subtitle-2")}
            </Card.Subtitle>
            <Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  {t("fortPage.intervention-article.list-1")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("fortPage.intervention-article.list-2")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("fortPage.intervention-article.list-3")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("fortPage.intervention-article.list-4")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("fortPage.intervention-article.list-5")}
                  <a href={mapFuerte} target="_blank" rel="noreferrer">
                    {t("fortPage.intervention-article.link-1")}
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("fortPage.intervention-article.subtitle-3")}
            </Card.Subtitle>
            <Card.Text>{t("fortPage.intervention-article.paraph-1")}</Card.Text>
            <Card.Text>{t("fortPage.intervention-article.paraph-2")}</Card.Text>
            <Card.Text>{t("fortPage.intervention-article.paraph-3")}</Card.Text>
            <Card.Text>{t("fortPage.intervention-article.paraph-4")}</Card.Text>
            <Card.Text>{t("fortPage.intervention-article.paraph-5")}</Card.Text>
            <Card.Text>{t("fortPage.intervention-article.paraph-6")}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("fortPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalHistory(props) {
  const [t] = useTranslation("global");

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("fortPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgFuerte63} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("fortPage.history-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("fortPage.history-article.title")}
            </Card.Title>
            <Card.Text>
              <Row>
                <Col xs={12} md={12} lg={8}>
                  <Card.Text>
                    {t("fortPage.history-article.paraph-1")}
                  </Card.Text>
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <Image fluid variant="top" src={imgFuerte67}></Image>
                </Col>
              </Row>
            </Card.Text>
            <Card.Text>
              <Row>
                <Col xs={12} md={12} lg={4}>
                  <Card.Text>
                    <Image fluid variant="top" src={imgFuerte68}></Image>
                  </Card.Text>
                </Col>
                <Col xs={12} md={12} lg={8}>
                  <Card.Text>
                    {t("fortPage.history-article.paraph-2")}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Text>
            <Card.Text>{t("fortPage.history-article.paraph-3")}</Card.Text>
            <Row>
              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte12}></Image>
                </Card.Text>
              </Col>

              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte14}></Image>
                </Card.Text>
              </Col>

              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte65}></Image>
                </Card.Text>
              </Col>

              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte25}></Image>
                </Card.Text>
              </Col>

              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte28}></Image>
                </Card.Text>
              </Col>

              <Col xs={12} md={12} lg={4} className="mb-1">
                <Card.Text>
                  <Image fluid variant="top" src={imgFuerte38}></Image>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("fortPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function FuerteColcura() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);

  return (
    <div>
      <SEO
        title={t("fortPage.title1")}
        description={t("fortPage.description")}
        name="CEIA LOTA"
        type={t("fortPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgFuerte76}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1>{t("fortPage.colcura-fort")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgFuerte41}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("fortPage.colcura-fort")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgFuerte68}
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("fortPage.colcura-fort")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgFuerte77} />
                <Card.Text className="mb-2 text-muted">
                  {t("fortPage.intervention-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("fortPage.intervention-article.title")}
                  </Card.Title>
                  <Card.Text>
                    {t("fortPage.intervention-article.decree-1")}
                    <a
                      href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("fortPage.intervention-article.decree-2")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("fortPage.intervention-article.category")}
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("fortPage.intervention-article.subtitle-1")}
                  </Card.Subtitle>
                  <Card.Text>
                    {t("fortPage.intervention-article.owner")}
                  </Card.Text>
                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow1(true)}
                  >
                    {t("fortPage.intervention-article.view-more")}
                  </Button>
                  <ModalIntervention
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={6} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgFuerte76} />
                <Card.Text className="mb-2 text-muted">
                  {t("fortPage.history-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>{t("fortPage.history-article.title")}</Card.Title>
                  <Card.Text>
                    {t("fortPage.history-article.paraph-preview")}
                  </Card.Text>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow2(true)}
                  >
                    {t("fortPage.history-article.view-more")}
                  </Button>
                  <ModalHistory
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default FuerteColcura;
