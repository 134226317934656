import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LayoutPublic.css";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";

import Footer from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import LogoFooter from "../components/LogosFooter/LogoFooter";
import { HelmetProvider } from "react-helmet-async";


const LayoutPublic = () => {
  return (
    <>
      <HelmetProvider>
        
        <ResponsiveAppBar></ResponsiveAppBar>
        <main className="container h-100">
          <Outlet />
        </main>
        <LogoFooter></LogoFooter>
        <Footer></Footer>
      </HelmetProvider>
    </>
  );
};
export default LayoutPublic;
