import React from "react";
import "./Footer.css";
import Image from "react-bootstrap/Image";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logoCeia from "../../assets/img/logo-ceia.png";

function Footer() {
  const [t] = useTranslation("global");

  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3">
            <Row className="justify-content-md-center">
              <Col xs={12} md={6} className="mt-2">
                <a
                  href="http://www.ceialota.cl"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image className="logo-ceia" variant="top" src={logoCeia}></Image>
                </a>
              </Col>
              <Col xs={12} md={6} className="mt-2 ceia-info">
                <p>Serrano 086, Lota, Región del Bío Bio, Chile</p>
                <p>+56412347585</p>
                <p>ceiamexicolota@gmail.com</p>
              </Col>
            </Row>
          </div>

          <hr className="clearfix w-100 d-md-none pb-0" />

          <div className="links col-md-6 mb-md-0 mb-3">
            <h5 className="text-uppercase">{t("footer.heritage")}</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/iglesia-san-matias">
                  {t("footer.san-matias-church")}
                </a>
              </li>
              <li>
                <a href="/mina-el-chiflon">{t("footer.chiflon-mine")}</a>
              </li>
              <li>
                <a href="/parque-isidora-cousino">
                  {t("footer.isidora-cousino-park")}
                </a>
              </li>
              <li>
                <a href="/fuerte-colcura">
                  {t("footer.colcura-fort")}
                </a>
              </li>
              <li>
                <a href="#!" style={{ pointerEvents: 'none' }}>{t("footer.other-heritage")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">
        C.E.I.A LOTA San Luis de Potosí © 2023 {t("footer.design")}:{" "}
        <a
          href="https://ceialota.cl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ceialota.cl
        </a>
      </div>
    </footer>
  );
}

export default Footer;
