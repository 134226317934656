import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import "./Home.css";
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

import imgChiflon1 from "../../assets/img/chiflon-1.jpg";
import imgChurch1 from "../../assets/img/iglesia-3.jpg";
import imgPark1 from "../../assets/img/parque-lota-1.jpg";
import imgFort1 from "../../assets/img/fuerte-0076.jpg";

function Home() {
  const [t] = useTranslation("global");
  return (
    <Container>
      <SEO
        title={t("home.title1")}
        description={t("home.description")}
        name="CEIA LOTA"
        type={t("home.heritage")}
      />
      <Row className="mt-3 mb-3">
        <Col xs={12} md={12}>
          <Card className="border-0 rounded-0">
            <Card.Title className="text-center mt-2">
              {t("home.title2")}
            </Card.Title>
            <Card.Body>
              <Card.Text>{t("home.paraph1")}</Card.Text>
              <Card.Text>
                {t("home.paraph2")}
                <a href="https://ceialota.cl" target="_blank" rel="noreferrer">
                  www.ceialota.cl
                </a>
                ).
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col xs={12} md={12}>
          <Card className="border-0 rounded-0">
            <Card.Title className="text-center mt-2">
              {t("home.title3")}
            </Card.Title>
            <Card.Body>
              <Card.Text>{t("home.paraph3")}</Card.Text>
              <Card.Text>{t("home.paraph4")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Card.Img
              className="border-0 rounded-0"
              variant="top"
              src={imgChiflon1}
            />
            <Card.Title className="text-center mt-2">
              {t("home.mine")}
            </Card.Title>
            <Card.Body className="text-center">
              <Button
                size="xl"
                variant="outline-primary"
                className="m-1"
                href="/mina-el-chiflon"
              >
                {t("home.go-to")}
                {t("home.mine-min")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Card.Img
              className="border-0 rounded-0"
              variant="top"
              src={imgChurch1}
            />
            <Card.Title className="text-center mt-2">
              {t("home.church")}
            </Card.Title>
            <Card.Body className="text-center">
              <Button
                size="xl"
                variant="outline-primary"
                className="m-1"
                href="/iglesia-san-matias"
              >
                {t("home.go-to")}
                {t("home.church-min")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Card.Img
              className="border-0 rounded-0"
              variant="top"
              src={imgPark1}
            />
            <Card.Title className="text-center mt-2">
              {t("home.park")}
            </Card.Title>
            <Card.Body className="text-center">
              <Button
                size="xl"
                variant="outline-primary"
                className="m-1"
                href="/parque-isidora-cousino"
              >
                {t("home.go-to")}
                {t("home.park-min")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Card.Img
              className="border-0 rounded-0"
              variant="top"
              src={imgFort1}
            />
            <Card.Title className="text-center mt-2">
              {t("home.fort")}
            </Card.Title>
            <Card.Body className="text-center">
              <Button
                size="xl"
                variant="outline-primary"
                className="m-1"
                href="/fuerte-colcura"
              >
                {t("home.go-to")}
                {t("home.fort-min")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
