import React from "react";
import "./IglesiaSanMatias.css";

import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import ReactPlayer from 'react-player';

import imgChurch1 from "../../assets/img/iglesia-3.jpg";
import imgChurch2 from "../../assets/img/iglesia-2.jpg";
import imgChurch3 from "../../assets/img/iglesia-1.jpg";
import imgChurch4 from "../../assets/img/iglesia-visita-1.jpg";
import imgChurch5 from "../../assets/img/iglesia-visita-2.jpg";
import imgChurch6 from "../../assets/img/iglesia-visita-3.jpg";
import imgChurch7 from "../../assets/img/iglesia-visita-4.jpg";
import imgChurch8 from "../../assets/img/iglesia-visita-5.jpg";
import imgChurch9 from "../../assets/img/iglesia-vitrales-1.jpg";

import vidChurch1 from "../../assets/videos/iglesia-video-1.mp4";

import mapChurch from "../../assets/pdf/plano_iglesia_san_matias.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

function ModalInterview(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("churchPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgChurch2} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("churchPage.interview-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("churchPage.interview-article.title")}
            </Card.Title>
            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/XiWUNNTe2O8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("churchPage.interview-article.subtitle-1")}
              </Card.Subtitle>
            </Card.Text>

            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/xITVCU-5lmk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("churchPage.interview-article.subtitle-2")}
              </Card.Subtitle>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("churchPage.interview-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("churchPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgChurch1} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("churchPage.intervention-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("churchPage.intervention-article.title")}
            </Card.Title>
            <Card.Text>
              {t("churchPage.intervention-article.decree-1")}
              <a
                href="https://www.monumentos.gob.cl/servicios/decretos/232_2014"
                target="_blank"
                rel="noreferrer"
              >
                {t("churchPage.intervention-article.decree-2")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.category")}
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("churchPage.intervention-article.subtitle-1")}
            </Card.Subtitle>
            <Card.Text>
              {t("churchPage.intervention-article.description")}
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("churchPage.intervention-article.subtitle-2")}
            </Card.Subtitle>
            <Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  {t("churchPage.intervention-article.list-1")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("churchPage.intervention-article.list-2")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("churchPage.intervention-article.list-3")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("churchPage.intervention-article.list-4")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("churchPage.intervention-article.list-5")}
                  <a href={mapChurch} target="_blank" rel="noreferrer">
                    {t("churchPage.intervention-article.link-1")}
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("churchPage.intervention-article.subtitle-3")}
            </Card.Subtitle>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-1")}
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-2")}
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-3")}
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-4")}
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-5")}
            </Card.Text>
            <Card.Text>
              {t("churchPage.intervention-article.paraph-6")}
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("churchPage.intervention-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function IglesiaSanMatias() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  return (
    <div>
      <SEO
        title={t("churchPage.title1")}
        description={t("churchPage.description")}
        name="CEIA LOTA"
        type={t("churchPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChurch1}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1>{t("churchPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChurch2}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("churchPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChurch3}
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("churchPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgChurch2} />
                <Card.Text className="mb-2 text-muted">
                  {t("churchPage.interview-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("churchPage.interview-article.title")}
                  </Card.Title>

                  <Card.Text>
                    <iframe
                      width="100%"
                      height="250"
                      src="https://www.youtube.com/embed/XiWUNNTe2O8"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("churchPage.interview-article.subtitle-1")}
                  </Card.Subtitle>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow3(true)}
                  >
                    {t("churchPage.interview-article.view-more")}
                  </Button>
                  <ModalInterview
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgChurch1} />
                <Card.Text className="mb-2 text-muted">
                  {t("churchPage.intervention-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("churchPage.intervention-article.title")}
                  </Card.Title>
                  <Card.Text>
                    {t("churchPage.intervention-article.decree-1")}
                    <a
                      href="https://www.monumentos.gob.cl/servicios/decretos/232_2014"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("churchPage.intervention-article.decree-2")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("churchPage.intervention-article.category")}
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("churchPage.intervention-article.subtitle-1")}
                  </Card.Subtitle>
                  <Card.Text>
                    {t("churchPage.intervention-article.description")}
                  </Card.Text>
                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow1(true)}
                  >
                    {t("churchPage.intervention-article.view-more")}
                  </Button>
                  <ModalIntervention
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("churchPage.intervention-article.video-dron")}
                  </Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/dztEn8IsZ2k"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                    <Carousel.Item>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/Ty25Xc981xE"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                  </Carousel>
                </Card.Body>
              </Card>
            </Col>
            {/* Visita pedagógica 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("churchPage.visit.title")}</Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch4}
                        alt={t("churchPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch5}
                        alt={t("churchPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch6}
                        alt={t("churchPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch7}
                        alt={t("churchPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch8}
                        alt={t("churchPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Card.Text>{t("churchPage.visit.visit-1")}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Vitrales */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("churchPage.stained-glass.title")}
                  </Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChurch9}
                        alt={t("churchPage.stained-glass.image-1")}
                      />
                      <Card.Text>
                        {t("churchPage.stained-glass.image-1")}
                      </Card.Text>
                    </Carousel.Item>
                  </Carousel>
                </Card.Body>
              </Card>
            </Col>
            {/* Tuberculosis */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("churchPage.videos.title")}
                  </Card.Title>
                  <ReactPlayer
                    url={vidChurch1}
                    controls={true}
                    width="100%"
                    height="400"
                    title={t("churchPage.videos.title")}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default IglesiaSanMatias;
