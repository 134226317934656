import React from "react";
import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import ReactPlayer from 'react-player';
import "./MinaElChiflon.css";
import imgChiflon1 from "../../assets/img/chiflon-1.jpg";
import imgChiflon2 from "../../assets/img/chiflon-2.jpg";
import imgChiflon3 from "../../assets/img/chiflon-3.jpg";
import imgChiflon4 from "../../assets/img/chiflon-4.jpg";
import imgChiflon5 from "../../assets/img/chiflon-5.png";
import imgChiflon6 from "../../assets/img/chiflon-6.jpg";
import imgChiflon7 from "../../assets/img/chiflon-7.jpg";
import imgChiflon8 from "../../assets/img/chiflon-8.jpg";
import imgChiflon9 from "../../assets/img/chiflon-9.jpg";
import imgChiflon10 from "../../assets/img/chiflon-10.jpg";
import imgChiflon11 from "../../assets/img/chiflon-11.jpg";
import imgChiflon12 from "../../assets/img/chiflon-12.jpg";
import imgChiflon13 from "../../assets/img/chiflon-13.jpg";
import imgChiflon14 from "../../assets/img/chiflon-14.jpg";
import imgChiflon15 from "../../assets/img/chiflon-15.jpg";
import imgChiflon16 from "../../assets/img/chiflon-16.jpg";
import imgChiflon17 from "../../assets/img/chiflon-17.jpg";
import imgChiflon18 from "../../assets/img/chiflon-18.jpg";
import imgChiflon19 from "../../assets/img/chiflon-19.jpg";
import vidChiflon1 from "../../assets/videos/video-chiflon-1.mp4";
import mapChiflon from "../../assets/pdf/plano_chiflon.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

function ModalInterview(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgChiflon8} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("chiflonPage.interview-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("chiflonPage.interview-article.title")}
            </Card.Title>
            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/wDppdxCPCBs?cc_load_policy=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("chiflonPage.interview-article.subtitle-1")}
              </Card.Subtitle>
            </Card.Text>

            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/OwAqFMRNtrQ?cc_load_policy=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("chiflonPage.interview-article.subtitle-2")}
              </Card.Subtitle>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgChiflon4} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("chiflonPage.intervention-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("chiflonPage.intervention-article.title")}
            </Card.Title>
            <Card.Text>
              {t("chiflonPage.intervention-article.decree-1")}
              <a
                href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                target="_blank"
                rel="noreferrer"
              >
                {t("chiflonPage.intervention-article.decree-2")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("chiflonPage.intervention-article.category")}
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("chiflonPage.intervention-article.subtitle-1")}
            </Card.Subtitle>
            <Card.Text>{t("chiflonPage.intervention-article.owner")}</Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("chiflonPage.intervention-article.subtitle-2")}
            </Card.Subtitle>
            <Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-1")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-2")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-3")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-4")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-5")}
                  <a href={mapChiflon} target="_blank" rel="noreferrer">
                    {t("chiflonPage.intervention-article.link-1")}
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("chiflonPage.intervention-article.subtitle-3")}
            </Card.Subtitle>
            <Card.Text>
              {t("chiflonPage.intervention-article.paraph-1")}
            </Card.Text>
            <Card.Text>
              {t("chiflonPage.intervention-article.paraph-2")}
            </Card.Text>
            <Card.Text>
              {t("chiflonPage.intervention-article.paraph-3")}
            </Card.Text>
            <Card.Text>
              {t("chiflonPage.intervention-article.paraph-4")}
            </Card.Text>
            <Card.Text>
              {t("chiflonPage.intervention-article.paraph-5")}
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalHistory(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgChiflon5} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("chiflonPage.history-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("chiflonPage.history-article.title")}
            </Card.Title>
            <Card.Text>{t("chiflonPage.history-article.paraph-1")}</Card.Text>
            <Card.Text>
              <Row>
                <Col xs={12} md={12} lg={8}>
                  <Card.Text>
                    {t("chiflonPage.history-article.paraph-2")}
                  </Card.Text>
                </Col>
                <Col xs={12} md={12} lg={4}>
                  <Image fluid variant="top" src={imgChiflon6}></Image>
                </Col>
              </Row>
            </Card.Text>
            <Card.Text>
              <Row>
                <Col xs={12} md={12} lg={4}>
                  <Card.Text>
                    <Image fluid variant="top" src={imgChiflon7}></Image>
                  </Card.Text>
                </Col>
                <Col xs={12} md={12} lg={8}>
                  <Card.Text>
                    {t("chiflonPage.history-article.paraph-3")}
                  </Card.Text>
                </Col>
              </Row>
            </Card.Text>
            <Card.Text>{t("chiflonPage.history-article.paraph-4")}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MinaElChiflon() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  return (
    <div>
      <SEO
        title={t("chiflonPage.title1")}
        description={t("chiflonPage.description")}
        name="CEIA LOTA"
        type={t("chiflonPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChiflon1}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1>{t("chiflonPage.chiflon-mine")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChiflon2}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("chiflonPage.chiflon-mine")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgChiflon3}
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("chiflonPage.chiflon-mine")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgChiflon8} />
                <Card.Text className="mb-2 text-muted">
                  {t("chiflonPage.interview-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.interview-article.title")}
                  </Card.Title>

                  <Card.Text>
                    <iframe
                      width="100%"
                      height="250"
                      src="https://www.youtube.com/embed/wDppdxCPCBs?cc_load_policy=1"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("chiflonPage.interview-article.subtitle-1")}
                  </Card.Subtitle>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow3(true)}
                  >
                    {t("chiflonPage.interview-article.view-more")}
                  </Button>
                  <ModalInterview
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgChiflon4} />
                <Card.Text className="mb-2 text-muted">
                  {t("chiflonPage.intervention-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.intervention-article.title")}
                  </Card.Title>
                  <Card.Text>
                    {t("chiflonPage.intervention-article.decree-1")}
                    <a
                      href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("chiflonPage.intervention-article.decree-2")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("chiflonPage.intervention-article.category")}
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("chiflonPage.intervention-article.subtitle-1")}
                  </Card.Subtitle>
                  <Card.Text>
                    {t("chiflonPage.intervention-article.owner")}
                  </Card.Text>
                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow1(true)}
                  >
                    {t("chiflonPage.intervention-article.view-more")}
                  </Button>
                  <ModalIntervention
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgChiflon5} />
                <Card.Text className="mb-2 text-muted">
                  {t("chiflonPage.history-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.history-article.title")}
                  </Card.Title>
                  <Card.Text>
                    {t("chiflonPage.history-article.paraph-preview")}
                  </Card.Text>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow2(true)}
                  >
                    {t("chiflonPage.history-article.view-more")}
                  </Button>
                  <ModalHistory
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            {/* Visita pedagógica 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("chiflonPage.visit.title")}</Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon14}
                        alt={t("chiflonPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon15}
                        alt={t("chiflonPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon16}
                        alt={t("chiflonPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon17}
                        alt={t("chiflonPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon18}
                        alt={t("chiflonPage.visit.visit-1")}
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Card.Text>{t("chiflonPage.visit.visit-1")}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Dibujos a carbon 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.charcoal-drawings.title")}
                  </Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon19}
                        alt={t("chiflonPage.charcoal-drawings.draw-1")}
                      />
                      <Card.Text>
                        {t("chiflonPage.charcoal-drawings.draw-1")}
                      </Card.Text>
                    </Carousel.Item>
                  </Carousel>
                </Card.Body>
              </Card>
            </Col>
            {/* Tuberculosis */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.miners-diseases.title")}
                  </Card.Title>
                  <ReactPlayer url={vidChiflon1} controls={true} width="100%"
                    height="400" title={t("chiflonPage.miners-diseases.title")} />
                </Card.Body>
              </Card>
            </Col>
            {/* Dibujos 2022 */}
            <Col md={12} lg={6} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("chiflonPage.drawings.title")}</Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon9}
                        alt={t("chiflonPage.drawings.draw-1")}
                      />
                      <Card.Text>{t("chiflonPage.drawings.draw-1")}</Card.Text>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon10}
                        alt={t("chiflonPage.drawings.draw-5")}
                      />
                      <Card.Text>{t("chiflonPage.drawings.draw-5")}</Card.Text>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon11}
                        alt={t("chiflonPage.drawings.draw-4")}
                      />
                      <Card.Text>{t("chiflonPage.drawings.draw-4")}</Card.Text>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon12}
                        alt={t("chiflonPage.drawings.draw-3")}
                      />
                      <Card.Text>{t("chiflonPage.drawings.draw-3")}</Card.Text>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgChiflon13}
                        alt={t("chiflonPage.drawings.draw-2")}
                      />
                      <Card.Text>{t("chiflonPage.drawings.draw-2")}</Card.Text>
                    </Carousel.Item>
                  </Carousel>
                </Card.Body>
              </Card>
            </Col>
            {/* Videos Dron */}
            <Col md={12} lg={6} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>
                    {t("chiflonPage.drawings.video-dron")}
                  </Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/nOaVkn7RJl8"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                    <Carousel.Item>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/JVBBLiv_Q1c"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                    <Carousel.Item>
                      <iframe
                        width="100%"
                        height="400"
                        src="https://www.youtube.com/embed/GJcgxOyg3Fs"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Carousel.Item>
                  </Carousel>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default MinaElChiflon;
