import * as React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./ResponsiveAppBar.css";
import { Button } from "react-bootstrap";
import { ES, US } from "country-flag-icons/react/3x2";
import { useTranslation } from "react-i18next";

function ResponsiveAppBar() {
  const [t, i18n] = useTranslation("global");
  return (
    <Navbar className="color-menu" expand="lg" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              alt=""
              src="/logo-ceia.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />
          </Link>{" "}
          CEIA SAN LUIS DE POTOSÍ
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="/">{t("nav.home")}</Nav.Link>
            <NavDropdown title={t("nav.heritage")} id="basic-nav-dropdown">
              <NavDropdown.Item href="/mina-el-chiflon">
                {t("nav.chiflon-mine")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/parque-isidora-cousino">
                {t("nav.isidora-cousino-park")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/iglesia-san-matias">
                {t("nav.san-matias-church")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/fuerte-colcura">
                {t("nav.colcura-fort")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                {t("nav.other-heritage")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Button
            size="sm"
            variant="outline-light"
            className="m-1 btn-lng"
            onClick={() => i18n.changeLanguage("es")}
          >
            ES <ES title={t("nav.spanish")} />
          </Button>

          <Button
            size="sm"
            variant="outline-light"
            className="m-1 btn-lng"
            onClick={() => i18n.changeLanguage("en")}
          >
            EN <US title={t("nav.english")} />
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default ResponsiveAppBar;
