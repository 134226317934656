import React from "react";
import "./LogoFooter.css";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import logoLota from "../../assets/img/logo-muni-lota.png";
import logoArzobispado from "../../assets/img/logo-arzobispado.png";
import logoCorfo from "../../assets/img/logo-corfo.png";
import logoCMN from "../../assets/img/logo-cmn.png";
import logoProcultura from "../../assets/img/logo-procultura.png";

function LogoFooter() {
  const [t] = useTranslation("global");

  return (
    <footer className="logo-footer font-small">
      <div className="container-fluid text-center text-md-left">
        <h5 className="pt-3">{t("footer.sponsored")}</h5>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6} lg={2} className="mt-2 logo">
            <a href="http://www.lota.cl" target="_blank" rel="noreferrer">
              <Image variant="top" src={logoLota}></Image>
            </a>
          </Col>
          <Col xs={12} md={6} lg={2} className="mt-2 logo">
            <a href="https://iglesiadeconcepcion.cl/" target="_blank" rel="noreferrer">
              <Image variant="top" src={logoArzobispado}></Image>
            </a>
          </Col>
          <Col xs={12} md={6} lg={2} className="mt-2 logo">
            <a href="https://www.corfo.cl/sites/cpp/homecorfo" target="_blank" rel="noreferrer">
              <Image
                className="logo-corfo"
                variant="top"
                src={logoCorfo}
              ></Image>
            </a>
          </Col>
          <Col xs={12} md={6} lg={2} className="mt-2 logo">
            <a href="https://www.monumentos.gob.cl/" target="_blank" rel="noreferrer">
              <Image variant="top" src={logoCMN}></Image>
            </a>
          </Col>
          <Col xs={12} md={6} lg={2} className="mt-2 logo">
            <a href="https://www.procultura.cl/" target="_blank" rel="noreferrer">
              <Image variant="top" src={logoProcultura}></Image>
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default LogoFooter;
