import React from "react";
import "./ParqueLota.css";

import { Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";

import imgPark1 from "../../assets/img/parque-lota-1.jpg";
import imgPark2 from "../../assets/img/parque-lota-2.jpg";
import imgPark3 from "../../assets/img/parque-lota-3.jpg";
import imgPark4 from "../../assets/img/parque-lota-4.jpg";
import imgPark5 from "../../assets/img/parque-lota-5.jpg";
import imgPark6 from "../../assets/img/parque-lota-6.png";
import imgPark8 from "../../assets/img/parque-lota-7.png";
import imgPark7 from "../../assets/img/parque-lota-8.png";
import imgPark9 from "../../assets/img/parque-lota-9.jpg";
import imgPark10 from "../../assets/img/parque-inauguracion-1.jpg";
import imgPark11 from "../../assets/img/parque-visita-1.jpg";
import imgPark12 from "../../assets/img/parque-visita-2.jpg";
import imgPark13 from "../../assets/img/parque-visita-3.jpg";
import imgPark14 from "../../assets/img/parque-visita-4.jpg";
import imgPark15 from "../../assets/img/parque-escultura-1.jpg";

import pdfPark1 from "../../assets/pdf/ficha-botanica-parque-1.pdf";
import pdfPark2 from "../../assets/pdf/ficha-botanica-parque-2.pdf";
import pdfPark3 from "../../assets/pdf/bitacora-1.pdf";
import pdfPark4 from "../../assets/pdf/bitacora-2.pdf";
import pdfPark5 from "../../assets/pdf/triptico-1.pdf";

import mapPark from "../../assets/pdf/plano_parque.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

function ModalInterview(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgPark5} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("parkPage.interview-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("parkPage.interview-article.title")}
            </Card.Title>
            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/cjp7sbbxlJ0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("parkPage.interview-article.subtitle-1")}
              </Card.Subtitle>
            </Card.Text>

            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/yqkTha6V2o4"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("parkPage.interview-article.subtitle-2")}
              </Card.Subtitle>
            </Card.Text>

            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/RhcEO0YN6yU"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("parkPage.interview-article.subtitle-3")}
              </Card.Subtitle>
            </Card.Text>
            <Card.Text>
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/rrmbzZdGO-8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
              <Card.Subtitle className="mb-2 text-center text-muted">
                {t("parkPage.interview-article.subtitle-4")}
              </Card.Subtitle>
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("parkPage.interview-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgPark4} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("parkPage.intervention-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("parkPage.intervention-article.title")}
            </Card.Title>
            <Card.Text>
              {t("parkPage.intervention-article.decree-1")}
              <a
                href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                target="_blank"
                rel="noreferrer"
              >
                {t("parkPage.intervention-article.decree-2")}
              </a>
            </Card.Text>
            <Card.Text>{t("parkPage.intervention-article.category")}</Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("parkPage.intervention-article.subtitle-1")}
            </Card.Subtitle>
            <Card.Text>{t("parkPage.intervention-article.owner")}</Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("parkPage.intervention-article.subtitle-2")}
            </Card.Subtitle>
            <Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  {t("parkPage.intervention-article.list-1")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("parkPage.intervention-article.list-2")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("parkPage.intervention-article.list-3")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("parkPage.intervention-article.list-4")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("parkPage.intervention-article.list-5")}
                  <a href={mapPark} target="_blank" rel="noreferrer">
                    {t("parkPage.intervention-article.link-1")}
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Card.Text>
            <Card.Subtitle className="mb-2 text-muted">
              {t("parkPage.intervention-article.subtitle-3")}
            </Card.Subtitle>
            <Card.Text>{t("parkPage.intervention-article.paraph-1")}</Card.Text>
            <Card.Text>{t("parkPage.intervention-article.paraph-2")}</Card.Text>
            <Card.Text>{t("parkPage.intervention-article.paraph-3")}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("parkPage.intervention-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalHistory(props) {
  const [t] = useTranslation("global");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Img variant="top" src={imgPark9} />
          <Card.Text className="mb-2 text-muted text-center">
            {t("parkPage.history-article.figure-1")}
          </Card.Text>
          <Card.Body>
            <Card.Title className="text-center">
              {t("parkPage.history-article.title")}
            </Card.Title>

            <Card.Text>
              {t("parkPage.history-article.flora-1")}
              <a href={imgPark6} target="_blank" rel="noreferrer">
                {t("parkPage.history-article.link")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("parkPage.history-article.flora-2")}
              <a href={imgPark7} target="_blank" rel="noreferrer">
                {t("parkPage.history-article.link")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("parkPage.history-article.flora-3")}
              <a href={imgPark8} target="_blank" rel="noreferrer">
                {t("parkPage.history-article.link")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("parkPage.history-article.flora-4")}
              <a href={pdfPark1} target="_blank" rel="noreferrer">
                {t("parkPage.history-article.link")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("parkPage.history-article.flora-5")}
              <a href={pdfPark2} target="_blank" rel="noreferrer">
                {t("parkPage.history-article.link")}
              </a>
            </Card.Text>
            <Card.Text>
              {t("parkPage.history-article.by-description")}
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ParqueLota() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  return (
    <div>
      <SEO
        title={t("parkPage.title1")}
        description={t("parkPage.description")}
        name="CEIA LOTA"
        type={t("parkPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgPark1}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1>{t("parkPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgPark2}
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("parkPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="img-chiflon d-block w-100"
                    src={imgPark3}
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                    <h1>{t("parkPage.title")}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgPark5} />
                <Card.Text className="mb-2 text-muted">
                  {t("parkPage.interview-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("parkPage.interview-article.title")}
                  </Card.Title>

                  <Card.Text>
                    <iframe
                      width="100%"
                      height="250"
                      src="https://www.youtube.com/embed/cjp7sbbxlJ0"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("parkPage.interview-article.subtitle-1")}
                  </Card.Subtitle>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow3(true)}
                  >
                    {t("parkPage.interview-article.view-more")}
                  </Button>
                  <ModalInterview
                    show={modalShow3}
                    onHide={() => setModalShow3(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgPark4} />
                <Card.Text className="mb-2 text-muted">
                  {t("parkPage.intervention-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>
                    {t("parkPage.intervention-article.title")}
                  </Card.Title>
                  <Card.Text>
                    {t("parkPage.intervention-article.decree-1")}
                    <a
                      href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("parkPage.intervention-article.decree-2")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.intervention-article.category")}
                  </Card.Text>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("parkPage.intervention-article.subtitle-1")}
                  </Card.Subtitle>
                  <Card.Text>
                    {t("parkPage.intervention-article.owner")}
                  </Card.Text>
                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow1(true)}
                  >
                    {t("parkPage.intervention-article.view-more")}
                  </Button>
                  <ModalIntervention
                    show={modalShow1}
                    onHide={() => setModalShow1(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Img variant="top" src={imgPark9} />
                <Card.Text className="mb-2 text-muted">
                  {t("parkPage.history-article.figure-1")}
                </Card.Text>
                <Card.Body>
                  <Card.Title>{t("parkPage.history-article.title")}</Card.Title>
                  <Card.Text>
                    {t("parkPage.history-article.flora-1")}
                    <a href={imgPark6} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.history-article.flora-2")}
                    <a href={imgPark7} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.history-article.flora-3")}
                    <a href={imgPark8} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.history-article.flora-4")}
                    <a href={pdfPark1} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.history-article.flora-5")}
                    <a href={pdfPark2} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.history-article.by-description")}
                  </Card.Text>

                  <Button
                    variant="outline-primary"
                    onClick={() => setModalShow2(true)}
                  >
                    {t("parkPage.history-article.view-more")}
                  </Button>
                  <ModalHistory
                    show={modalShow2}
                    onHide={() => setModalShow2(false)}
                  />
                </Card.Body>
              </Card>
            </Col>
            {/* Inauguración */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Title>{t("parkPage.opening.title")}</Card.Title>
                <Card.Body>
                  <img
                    className="d-block w-100"
                    src={imgPark10}
                    alt={t("parkPage.opening.photo-1")}
                  />
                  <Card.Text>{t("parkPage.opening.photo-1")}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Visita pedagógica */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("parkPage.visit.title")}</Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgPark11}
                        alt={t("parkPage.visit.desc-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgPark12}
                        alt={t("parkPage.visit.desc-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgPark13}
                        alt={t("parkPage.visit.desc-1")}
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgPark14}
                        alt={t("parkPage.visit.desc-1")}
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Card.Text>{t("parkPage.visit.desc-1")}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Bitacora de Viaje */}
            <Col md={12} lg={4} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("parkPage.travel-log.title")}</Card.Title>
                  <Card.Text>
                    {t("parkPage.travel-log.doc-1")}
                    <a href={pdfPark3} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.travel-log.doc-2")}
                    <a href={pdfPark4} target="_blank" rel="noreferrer">
                      {t("parkPage.history-article.link")}
                    </a>
                  </Card.Text>
                  <Card.Text>
                    {t("parkPage.travel-log.by-description")}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Triptico Inglés */}
            <Col md={12} lg={6} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("parkPage.triptych.title")}</Card.Title>
                  <Card.Text>
                    {t("parkPage.triptych.triptych-1")}
                    <a href={pdfPark5} target="_blank" rel="noreferrer">
                      {t("parkPage.triptych.link")}
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {/* Esculturas */}
            <Col md={12} lg={6} className="mb-2">
              <Card className="text-center border-0 rounded-0">
                <Card.Body>
                  <Card.Title>{t("parkPage.sculptures.title")}</Card.Title>
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={imgPark15}
                        alt={t("parkPage.sculptures.desc-1")}
                      />
                    </Carousel.Item>
                  </Carousel>
                  <Card.Text>{t("parkPage.sculptures.desc-1")}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ParqueLota;
